import React from "react";
import { Link } from "gatsby";

import '../css/full-copy.css';

const FullCopy = ({ content, section }) => {
  
  return (
    <div className="full-copy">
      <h2 className={`headline ${content.headlinePosition}`}>{content.headline}</h2>
      <div className={`content ${content.copyPosition}`} dangerouslySetInnerHTML={{ __html: content.copy }} />
      <div className="button-wrapper">
      {content.buttons && (
      <>
          {content.buttons.map((button, index) => (
            <Link key={index} to={button.link.url} target={button.link.target}>
              <button className={button.buttonType}>
                {button.buttonLabel}
              </button>
            </Link>
          ))}
        </>
      )}
      </div>
    </div>
  );
}

export default FullCopy;
